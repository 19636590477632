<template>
  <div class='mt-sm-10'>
    <h4 class="title mb-6">{{ $t('track_page.delivery_history') }}</h4>
    <a-timeline>
      <template v-for="(status, i) in statuses">
        <a-timeline-item
          v-if="i < 3 || show"
          :key="i"
          class="pl-3"
          color="#E5E5E5"
        >
          <template #dot>
            <div
              :class="{
                'step-icon': true,
                'step-icon_active': i === 0
              }"
            />
          </template>
          <a-descriptions class="status-description">
            <template #title>
              <h5 class="status-label">
                {{ status.status }}
              </h5>
              <span class="info">
                <template v-if="status.location && status.location !== ''">
                  {{ status.location }}
                  <br>
                </template>
                {{ status.date }}
              </span>
            </template>
          </a-descriptions>
        </a-timeline-item>
      </template>
    </a-timeline>
    <a-button
      class="btn btn-grey"
      @click="showMore"
    >
      {{ show ? $t('links.less') : $t('links.more') }}
      <DownOutlined :rotate="show ? 180 : 0" />
    </a-button>
  </div>
</template>

<script>
import {DownOutlined} from '@ant-design/icons-vue';

export default {
  name: 'DeliveryHistory',
  components: {DownOutlined},
  props: {
    statuses: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showMore() {
      this.show = !this.show;
      if (!this.show) {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-family: Raleway,sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.48px;
  text-align: left;
  color: #07011F;
}

.status-label {
  font-family: Raleway,sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  text-align: left;
  color: #07011F;
  margin-bottom: 2px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-transform: capitalize;
}

.info {
  font-family: Raleway,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717171;

}

.btn-grey {
  background-color: #E5E5E5 !important;
  color: #07011F;
  font-family: Raleway,sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
}

::v-deep {
  .ant-timeline-item-head {
    padding: 0;
  }
}
</style>