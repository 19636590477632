<template>
  <section id="delivery" style="scroll-margin-top: 150px;">
    <a-row class="section-row w100 delivery-row">
      <a-col
        :md="{ span: 15, order: 1 }"
        :sm="{ span: 24, order: 1 }"
        :xs="{ span: 24, order: 1 }"
        class="flex align-center justify-center pr-2 mt-sm-10 w100"
      >
        <img
          src="@/assets/images/Map-add-mexico.png"
          alt="map"
          class="bar-img map"
        >
      </a-col>
      <a-col
        :md="{ span: 9, order: 2 }"
        :sm="{ span: 24, oreder: 2 }"
        :xs="{ span: 24, oreder: 2 }"
        class="right-bar flex flex-column align-center justify-center mt-md-15 mt-sm-0 w100"
      >
        <div class="w100">
          <h5 class="deliver-doc-title mb-6">
            {{ $t('deliverySection.title') }}
          </h5>
          <a-row class="w100">
            <a-col
              :span="12"
              class="pr-2"
            >
              <div class="flex align-center justify-start">
                <img
                  src="@/assets/images/RU.png"
                  alt="Russia"
                  class="icon-flag mr-2"
                >
                <span class="country-name">{{ $t('deliverySection.countries.russia') }}</span>
              </div>
              <div class="flex align-center justify-start mt-3">
                <img
                  src="@/assets/images/KZ.png"
                  alt="Kazakhstan"
                  class="icon-flag mr-2"
                >
                <span class="country-name">{{ $t('deliverySection.countries.kazakhstan') }}</span>
              </div>
              <div class="flex align-center justify-start mt-3">
                <img
                  src="@/assets/images/BY.png"
                  alt="Belarus"
                  class="icon-flag mr-2"
                >
                <span class="country-name">{{ $t('deliverySection.countries.belarus') }}</span>
              </div>
              <div class="flex align-center justify-start mt-3">
                <img
                  src="@/assets/images/KG.png"
                  alt="Kyrgyzstan"
                  class="icon-flag mr-2"
                >
                <span class="country-name">{{ $t('deliverySection.countries.kyrgyzstan') }}</span>
              </div>
            </a-col>
            <a-col :span="12" class='px-sm-4 px-lg-5'>
              <div class="flex align-center justify-start">
                <img
                  src="@/assets/images/flag-mexico.png"
                  alt="Mexico"
                  class="icon-flag mr-2"
                  width='14px'
                >
                <span class="country-name">{{ $t('deliverySection.countries.mexico') }}</span>
              </div>
              <div class="flex align-center justify-start mt-3">
                <img
                  src="@/assets/images/UZ.png"
                  alt="Uzbekistan"
                  class="icon-flag mr-2"
                >
                <span class="country-name">{{ $t('deliverySection.countries.uzbekistan') }}</span>
              </div>
              <div class="flex align-center justify-start mt-3">
                <img
                  src="@/assets/images/TJ.png"
                  alt="Tajikistan"
                  class="icon-flag mr-2"
                >
                <span class="country-name">{{ $t('deliverySection.countries.tajikistan') }}</span>
              </div>
              <div class="flex align-center justify-start mt-3">
                <img
                  src="@/assets/images/AM.png"
                  alt="Armenia"
                  class="icon-flag mr-2"
                >
                <span class="country-name">{{ $t('deliverySection.countries.armenia') }}</span>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </section>
</template>

<script>
export default {
  name: 'DeliverySection',
}
</script>

<style scoped>
.delivery-row{
  margin-top: 180px;
}

.deliver-doc-title{
  font-family: Raleway;
  font-size: 36px;
  font-weight: 500;
  line-height: 42.26px;
  text-align: left;
  color: #000000;
}

.country-name{
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.24px;
  text-align: left;
  color: #07011F;
}

@media screen and (max-width: 1050px) {
  .deliver-doc-title{
    font-size: 30px;
    line-height: 36px;
  }

  .country-name{
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1050px) {
  .deliver-doc-title{
    font-size: 30px;
    line-height: 36px;
  }

  .country-name{
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 850px) {
  .deliver-doc-title{
    font-size: 26px;
    line-height: 30px;
  }

  .country-name{
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 766px){
  .deliver-doc-title{
    font-size: 24px;
    line-height: 28.18px;
  }

  .country-name{
    font-size: 20px;
    line-height: 27.24px;
  }

  .map{
    margin-top: 36px;
  }

  .delivery-row{
    margin-top: 100px;
  }
}
</style>